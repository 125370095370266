import React, { useState } from "react";
import CyrillToLatin from "./components/CyrillToLatin";
import Demo from "./components/Demo";
import "./App.css";

function App() {
  const [text, setText] = useState("");
  const [type, setType] = useState("cyrill");

  const handleConvert = (e) => {
    setText(e.target.value);
  };
  const handleChange = (e) => {
    setType(e.target.value);
  };
  const clearAll = (e) => {
    e.preventDefault();
    setText("");
  };

  return (
    <div>
      <header>
        <h2 className="center">
          {type === "cyrill" ? "Lotindan Kirillga" : "Kirilldan Lotinga"}
        </h2>
      </header>

      <div className="container">
        <form>
          <div className="content">
            <div className="box">
              <div className="left selection m-t-5">
                <select value={type} onChange={handleChange}>
                  <option value="latin">Kirill - Lotin</option>
                  <option value="cyrill">Lotin - Kirill</option>
                </select>
                <span>
                  <button className="btn btn-clear right" onClick={clearAll}>
                    Tozalash
                  </button>
                </span>
              </div>

              <div>
                <textarea
                  value={text}
                  className="text__area"
                  placeholder="Matnni kiriting"
                  onChange={handleConvert}
                />
              </div>
            </div>

            {type === "cyrill" ? (
              <Demo text={text} />
            ) : (
              <CyrillToLatin text={text} />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
