import React, { useRef, useState } from "react";

const CyrillToLatin = ({ text }) => {
  const a = {
    // Katta harflar
    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Е: "Ye",
    Ё: "Yo",
    Ж: "J",
    З: "E",
    И: "I",
    Й: "Y",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ф: "F",
    Х: "X",
    Ц: "S",
    Ч: "Ch",
    Ш: "Sh",
    Ъ: "'",
    Ь: "",
    Э: "E",
    Ю: "Yu",
    Я: "Ya",
    Ў: "O'",
    Қ: "Q",
    Ғ: "G'",
    Ҳ: "H",
    Ы: "I",
    // Kichik harflar
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "yo",
    ж: "j",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "x",
    ц: "s",
    ч: "ch",
    ш: "sh",
    ъ: "'",
    ь: " ",
    э: "e",
    ю: "yu",
    я: "ya",
    ў: "o'",
    қ: "q",
    ғ: "g'",
    ҳ: "h",
    ы: "i",
  };

  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    e.preventDefault();
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess(true);
  }

  const handleConvertText = (fullText) => {
    return fullText
      .split("")
      .map(function (char) {
        if (char === "ь" || char === "Ь") {
          return "";
        }
        return a[char] || char;
      })
      .join("");
  };

  return (
    <div className="box">
      {
        /* Logical shortcut for only displaying the 
          button if the copy command exists */
        document.queryCommandSupported("copy") && (
          <div className="left selection m-t-5">
            <button className="btn btn-primary" onClick={copyToClipboard}>
              Nusxalash
            </button>
            {copySuccess ? (
              <div className="msgSuccess">Ko`chirildi !</div>
            ) : null}
          </div>
        )
      }

      <textarea
        className="text__area result__textare"
        readOnly={true}
        ref={textAreaRef}
        value={handleConvertText(text)}
      />
    </div>
  );
};

export default CyrillToLatin;
