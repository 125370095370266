import React, { useRef, useState } from "react";

const Demo = ({ text }) => {
  const getCyrillicText = (latinText) => {
    if (!latinText) {
      return "";
    }
    var cyrText = "";
    for (var i = 0, len = latinText.length; i < len; i++) {
      var curLetter = latinText[i];
      if (curLetter === "e" || curLetter === "E") {
        //prevent pre E, erkin
        if (i === 0 || " -.,\n)('?/".indexOf(latinText[i - 1]) !== -1) {
          curLetter += curLetter;
        }
      }

      var pos1Txt = latinText[i + 1];
      var pos2Txt = latinText[i + 2];

      if (
        !(
          (curLetter === "y" || curLetter === "Y") &&
          (pos2Txt === "'" || pos2Txt === "’" || pos2Txt === "`")
        ) &&
        i !== len - 1 &&
        !(curLetter === "t" && pos1Txt === "s" && latinText[i + 3] === "z")
      ) {
        var dualLetter = latCyr[curLetter + pos1Txt];
        if (dualLetter) {
          cyrText += dualLetter;
          i++;
          continue;
        }
      }
      cyrText += latCyr[curLetter] || curLetter;
    }
    return cyrText;
  };

  var latCyr = {
    //   Katta harflar
    A: "А",
    B: "Б",
    V: "В",
    G: "Г",
    D: "Д",
    Ye: "Е",
    YE: "Е",
    J: "Ж",
    Z: "З",
    I: "И",
    Y: "Й",
    K: "К",
    L: "Л",
    M: "М",
    N: "Н",
    O: "О",
    P: "П",
    R: "Р",
    S: "С",
    T: "Т",
    U: "У",
    F: "Ф",
    X: "Х",
    Ts: "Ц",
    TS: "Ц",
    Ch: "Ч",
    CH: "Ч",
    Sh: "Ш",
    SH: "Ш",
    EE: "Э",
    Yu: "Ю",
    YU: "Ю",
    Ya: "Я",
    YA: "Я",
    "G'": "Ғ",
    "G`": "Ғ",
    "G’": "Ғ",
    "O'": "Ў",
    "O`": "Ў",
    "O’": "Ў",
    Yo: "Ё",
    YO: "Ё",
    Q: "Қ",
    H: "Ҳ",
    // Kichik harflar
    a: "а",
    b: "б",
    v: "в",
    g: "г",
    d: "д",
    ye: "е",
    yE: "е",
    j: "ж",
    z: "з",
    i: "и",
    y: "й",
    k: "к",
    l: "л",
    m: "м",
    n: "н",
    o: "о",
    p: "п",
    r: "р",
    s: "с",
    t: "т",
    u: "у",
    f: "ф",
    x: "х",
    ts: "ц",
    tS: "ц",
    ch: "ч",
    cH: "ч",
    sh: "ш",
    sH: "ш",
    "'": "ъ",
    "’": "ъ",
    "`": "ъ",
    ee: "э",
    eE: "э",
    e: "е",
    yu: "ю",
    yU: "ю",
    ya: "я",
    yA: "я",
    "o'": "ў",
    "o`": "ў",
    "o’": "ў",
    q: "қ",
    "g'": "ғ",
    "g`": "ғ",
    "g’": "ғ",
    yo: "ё",
    yO: "ё",
    h: "ҳ",
  };

  const [copySuccessCyrill, setCopySuccessCyrill] = useState("");
  const textAreaRefCyrill = useRef(null);

  function copyToClipboard(e) {
    e.preventDefault();
    textAreaRefCyrill.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccessCyrill(true);
  }

  return (
    <div className="box">
      {
        /* Logical shortcut for only displaying the 
          button if the copy command exists */
        document.queryCommandSupported("copy") && (
          <div className="left selection m-t-5">
            <button className="btn btn-primary" onClick={copyToClipboard}>
              Nusxalash
            </button>
            {copySuccessCyrill ? (
              <p className="msgSuccess">Ko`chirildi !</p>
            ) : null}
          </div>
        )
      }

      <div>
        <textarea
          className="text__area result__textare"
          readOnly={true}
          ref={textAreaRefCyrill}
          rows="30"
          cols="80"
          value={getCyrillicText(text)}
        />
      </div>
    </div>
  );
};

export default Demo;
